import algoliasearch from 'algoliasearch/lite';
import {Injectable} from '@angular/core';
import {environment} from "@env/environment";

@Injectable({
    providedIn: 'root' // This makes AlgoliaService available throughout the application
})
export class AlgoliaService {
    private client = algoliasearch(environment.algolia.appId, environment.algolia.searchKey);
    private index = this.client.initIndex('eqip_products');

    search(query: string, options?: { filters?: string, page?: number, hitsPerPage?: number, category?: string }) {
        let combinedFilters = options?.filters ? options.filters : '';

        const excludePanavisionFilter = `NOT properties.vendors:"Panavision"`;
        const excludeProdEnvFilter = `NOT _env:"prod"`;
        combinedFilters = combinedFilters ? `${combinedFilters} AND ${excludePanavisionFilter} AND ${excludeProdEnvFilter}` : `${excludePanavisionFilter} AND ${excludeProdEnvFilter}`;

        if (options?.category) {
            const categoryFilter = `properties.category:'${options.category}'`;
            combinedFilters = combinedFilters ? `${combinedFilters} AND ${categoryFilter}` : categoryFilter;
        }

        return this.index.search(query, {
            filters: combinedFilters,
            hitsPerPage: options?.hitsPerPage || 20,
            page: options?.page || 0
        });
    }


    //grabs the values of my facets filters. So if i am grabbing brand it will grab Canon, Sony, Fujinon...
    async getFacetValues(facet: string, filters: string = '', maxFacetHits: number = 100, category?: string) {
        let combinedFilters = filters;

        const excludePanavisionFilter = `NOT properties.vendors:"Panavision"`;
        const excludeProdEnvFilter = `NOT _env:"prod"`;
        combinedFilters = combinedFilters ? `${combinedFilters} AND ${excludePanavisionFilter} AND ${excludeProdEnvFilter}` : `${excludePanavisionFilter} AND ${excludeProdEnvFilter}`;

        if (category) {
            const categoryFilter = `properties.category:'${category}'`;
            combinedFilters = combinedFilters ? `${combinedFilters} AND ${categoryFilter}` : categoryFilter;
        }

        const response = await this.index.searchForFacetValues(facet, '', {
            maxFacetHits,
            filters: combinedFilters,
        });
        // return response.facetHits.map(facetHit => ({
        //     value: facetHit.value,
        //     count: facetHit.count
        // }));
        //Trying to remove Panavision
        //Not the best but will do until we get approval from Panavision to include there products
        return response.facetHits
            .filter(facetHit => facetHit.value !== 'Panavision' && facetHit.value !== 'PV')
            .map(facetHit => ({
                value: facetHit.value,
                count: facetHit.count
            }));
    }

}
