import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {Address, User} from '@nxt/model-core';
import {EqipQuote} from "@nxt/model-eqip";
import {OrderItem} from "@nxt/model-core";
import {CartService, ICartDocument} from '@library/shared/_services/cart.service';

export interface IEqipCartDocument extends ICartDocument {
    exists?: boolean;
    checkout: EqipQuote;
    user?: User;
    date?: number;
    receipts?: string[];
    status?: any;
}

@Injectable({
    providedIn: 'root'
})
export class EqipCartService extends CartService {
    cartDocument$: BehaviorSubject<IEqipCartDocument> = new BehaviorSubject<IEqipCartDocument>(null);
    activeItem$: BehaviorSubject<OrderItem> = new BehaviorSubject<OrderItem>(null);

    loadDocument(document: IEqipCartDocument): IEqipCartDocument {
        super.loadDocument(document);
        document.checkout = new EqipQuote(document.checkout);
        return document;
    }

    emitDocument(c: any) {
        let document:IEqipCartDocument = c.exists() ? c.data() : {};
        document.exists = c.exists();
        this.loadDocument(document);
        this.cartDocument$.next(document);
    }


    copyCart(document: IEqipCartDocument): IEqipCartDocument {
        if (document) {
            let copy: any = JSON.parse(JSON.stringify(this.toFullJSON(document)));
            return this.loadDocument(copy);
        }
        return document;
    }

    inCart(item: OrderItem, cart: IEqipCartDocument) {
        return !!this.findItem(item, cart);
    }

    findIndex(item: OrderItem, cart: IEqipCartDocument): number {
        // Ensure that cart.checkout and cart.checkout.items are defined
        if (cart?.checkout?.items) {
            return cart.checkout.items.findIndex(i => i.sku === item.sku);
        } else {
            console.error('Cart items are undefined');
            return -1; // Return -1 if the items array is undefined
        }
    }
    async save(cart: IEqipCartDocument) {
        await this.saveToFb(cart);
        this.calculating$.next(false);
        // this.pSvc.loading$.next(false);
    }

    async saveToFb(cart: IEqipCartDocument) {
        if (this.cSvc.u$.getValue()) {
            try {
                await this.fSvc.set(`carts/${this.cSvc.u$.getValue().uid}`, this.toFullJSON(cart));
            } catch (e) {
                try {
                    console.warn(`CART SAVE ERROR: ${e.toString()}`,  this.toFullJSON(cart));
                } catch (er) {
                    console.warn(`CART SAVE ERROR 2`,  e);
                }
            }
            this.watch(this.cSvc.u$.getValue());
        } else {
            console.warn(`Cannot save cart! No user`);
        }
    }
    async addCartItem(cart: IEqipCartDocument, item: OrderItem, skipSave?: boolean, skipShip?: boolean) {
        let i: number = this.findIndex(item, cart);

        cart.checkout.items = cart.checkout.items || [];
        if (i > -1) {
            cart.checkout.items[i] = item;
        } else {
            cart.checkout.items.push(item);
            await this.aSvc.track('mixpanel', 'Added to Cart', {
                'index': item.index,
                'sku': item.sku,
                'name': item.name,
                'quantity': item.quantity
            });
            await this.aSvc.track('ga', "add_to_cart", {
                'index': item.index,
                'sku': item.sku,
                'name': item.name,
                'quantity': item.quantity
            });
        }
        if (!skipSave) {
            await this.save(cart);
        }
    }
    async removeCartItem(cart: IEqipCartDocument, item: OrderItem): Promise<void> {
        // First, find the index of the item in the cart
        let index: number = this.findIndex(item, cart);

        // Check if the index is valid
        if (index !== -1) {
            // Proceed with removing the item
            cart.checkout.items.splice(index, 1);
            await this.save(cart);
        } else {
            // Handle the case where the item is not found
            throw new Error('Item not found in cart');
        }
    }

    async updateCartItem(cart: IEqipCartDocument, updatedItem: OrderItem): Promise<void> {
        const index = this.findIndex(updatedItem, cart);
        if (index !== -1) {
            // Item found, update its properties
            Object.assign(cart.checkout.items[index], updatedItem);
            await this.save(cart);
        } else {
            console.error('Item not found in cart for update:', updatedItem.sku);
        }
    }

    async updateCartCheckout(newQuote: EqipQuote) {
        let currentCart = this.cartDocument$.getValue();
        if (currentCart) {
            currentCart.checkout = new EqipQuote(newQuote);
            await this.save(currentCart);
        } else {
            console.error('No current cart found or cart checkout is undefined');
        }
    }

}
