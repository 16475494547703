interface FacetWithCount {
    value: string;
    count: number;
}

interface FacetConfig {
    name: string;
    data: FacetWithCount[];
    label: string;
}


export const equipmentPageConfigs = {
    camera: {
        imageUrl: '../../assets/images/camera_background3.jpg',
        message: {
            heading: "Camera",
            content: "Select your desired camera products and let Equipio find the best match for you. If you are unable to locate a specific model, simply include a note in your quote. We will contact our vendors to either source the exact model or offer a suitable alternative."
        },
        facets: [
            {name: 'properties.brand', data: [], label: 'Brand'},
            {name: 'properties.resolution', data: [], label: 'Resolution'},
            {name: 'properties.frame_rate', data: [], label: 'Frame Rate'},
            {name: 'properties.sensor_size', data: [], label: 'Sensor Size'},
            {name: 'properties.lens_mount', data: [], label: 'Lens Mount'},
            {name: 'properties.media_type', data: [], label: 'Media Type'},
            {name: 'properties.sensor_coverage', data: [], label: 'Sensor Coverage'},
            {name: 'properties.zoom', data: [], label: 'Zoom'},
            {name: 'properties.t_stop_min', data: [], label: 'T-Stop'},
            {name: 'properties.f_stop_min', data: [], label: 'F-Stop'},
            {name: 'properties.type', data: [], label: 'Type'},
            {name: 'properties.size', data: [], label: 'Size'},
            {name: 'properties.data_cables', data: [], label: 'Data Cables'},
            {name: 'properties.storage_capacity', data: [], label: 'Storage Capacity'},
            {name: 'properties.load_capacity', data: [], label: 'Load Capacity'},
            {name: 'properties.subcategory_lvl1', data: [], label: 'Subcategory'},
            {name: 'properties.subcategory_lvl2', data: [], label: 'Subcategory'},
            {name: 'properties.subcategory_lvl3', data: [], label: 'Subcategory'},
        ] as FacetConfig[],
        sliders:[
            // {min: 'properties.t_stop_min',max:'properties.t_stop_max', data: [], label: 'T-Stop'},
            {min: 'properties.f_stop_min',max:'properties.f_stop_max', data: [], label: 'F-Stop'},


        ]
    },
    audio: {
        imageUrl: '../../assets/images/audio_background.jpg',
        message: {
            heading: "Audio",
            content: "Choose your desired audio equipment products and Equipio will handle the rest. Can't find what you need? Leave a note in your quote and we'll consult our vendors for you."
        },
        facets: [
            {name: 'properties.brand', data: [], label: 'Brand'},
            {name: 'properties.type', data: [], label: 'Type'},
            {name: 'properties.subcategory_lvl1', data: [], label: 'Subcategory'},
            {name: 'properties.subcategory_lvl2', data: [], label: 'Subcategory'},
            {name: 'properties.subcategory_lvl3', data: [], label: 'Subcategory'}

        ] as FacetConfig[],
    },
    mobileTruckUnits: {
        imageUrl: '../../assets/images/mtu_background.jpg',
        message: {
            heading: "Mobile Truck Unit",
            content: "Specify the truck for your production and let Equipio locate the best mobile truck units available. If the exact truck isn't listed, include a note in your quote and we'll search through our vendor network."
        },
        facets: [
            {name: 'properties.location', data: [], label: 'Location'},
            {name: 'properties.brand', data: [], label: 'Brand'},
            {name: 'properties.truck_size', data: [], label: 'Truck Size'},
            {name: 'properties.truck_camera_resolution', data: [], label: 'Camera Resolutions'},
            {name: 'properties.camera_quantity', data: [], label: 'Camera Quantity'},
            {name: 'properties.subcategory_lvl1', data: [], label: 'Subcategory'},
            {name: 'properties.subcategory_lvl2', data: [], label: 'Subcategory'},
            {name: 'properties.subcategory_lvl3', data: [], label: 'Subcategory'}
        ] as FacetConfig[],
    },
    lighting: {
        imageUrl: '../../assets/images/lighting_background.jpg',
        message: {
            heading: "Lighting",
            content: "Choose the lighting products that best suit your needs, and let Equipio find the perfect match for you. If you're unable to find a specific model, simply add a note in your quote. We'll reach out to our vendors to either locate the exact model or suggest an appropriate alternative."
        },
        facets: [
            {name: 'properties.brand', data: [], label: 'Brand'},
            {name: 'properties.type', data: [], label: 'Type'},
            {name: 'properties.brightness', data: [], label: 'Brightness'},
            {name: 'properties.refresh_rate', data: [], label: 'Refresh Rates'},
            {name: 'properties.environment', data: [], label: 'Environment'},
            {name: 'properties.power_requirements', data: [], label: 'Power Requirements'},
            {name: 'properties.subcategory_lvl1', data: [], label: 'Subcategory'},
            {name: 'properties.subcategory_lvl2', data: [], label: 'Subcategory'},
            {name: 'properties.subcategory_lvl3', data: [], label: 'Subcategory'}
        ] as FacetConfig[],
    },
};


export const footerConfig = {
    image: 'https://firebasestorage.googleapis.com/v0/b/nxtpart-dev.appspot.com/o/pages%2FEQIP%2Fqvzweiv%2Feqip-footer%2Fimage%2F1.png?alt=media',
    address: '436 Main St. Franklin,TN 37064-2720',
    phone: '(607) 765-3315',
    columns: [
        {
            "header": "Equipment",
            "rows": [
                {
                    "page_label": "Cameras",
                    "url": "/camera"
                },
                {
                    "page_label": "Audio",
                    "url": "/audio"
                },
                {
                    "page_label": "Lighting",
                    "url": "/lighting"
                },
                {
                    "page_label": "Grip",
                    "url": "/grip"
                },
                {
                    "page_label": "Electric",
                    "url": "/electric"
                },
                {
                    "page_label": "Video",
                    "url": "/video"
                },
                {
                    "page_label": "Mobile Truck Units",
                    "url": "/mobile-truck-units"
                },
                {
                    "page_label": "Production Supplies",
                    "url": "/production-supplies"
                }
            ]
        },
        {
            "header": "Company",
            "rows": [
                {
                    "page_label": "About Us",
                    "url": "/about-us"
                },
                {
                    "page_label": "Contact Us",
                    "url": "/contact-us"
                },
                {
                    "page_label": "Privacy Policy",
                    "url": "/privacy-policy"
                },
                {
                    "page_label": "Terms of Use",
                    "url": "/terms-of-use"
                }
            ]
        }
    ],
    selector: 'eqip-footer',
    id: 'eka9jblojumfhkilhovfax',
    email: 'Support@Equipio.com'
};
