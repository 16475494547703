import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {FormGroup} from "@angular/forms";
import {Contact} from "@nxt/model-core";
import {ClientService} from "../../../../_library/shared/_services/client.service";
import {PageService} from "../../../../_library/shared/_services/page.service";
import {Router} from "@angular/router";
import {Agreement} from "../_components/policy/policy.component";


@Injectable({
    providedIn: 'root',
})
export class SharedService {
    private quoteSource = new BehaviorSubject<any>(null);

    private formStateKey = 'checkoutFormState';
    currentQuote = this.quoteSource.asObservable();

    private contactSubject: BehaviorSubject<Contact | null> = new BehaviorSubject<Contact | null>(null);
    contact$: Observable<Contact | null> = this.contactSubject.asObservable();

    private agreementsSubject: BehaviorSubject<Agreement[]> = new BehaviorSubject<Agreement[]>([]);
    agreements$: Observable<Agreement[]> = this.agreementsSubject.asObservable();

    constructor(private cSvc: ClientService, private pSvc: PageService, private router: Router) {
    }

    updateQuote(quote: any) {
        this.quoteSource.next(quote);
    }

    async fetchContact(): Promise<Contact | null> {
        if (!this.contactSubject.value) {
            try {
                const contact = await this.cSvc.callAPI('/contact/getContact', 'get');
                this.contactSubject.next(contact);
                if (!contact.verified) {
                    this.pSvc.notification$.next({
                        title: 'Verification Required',
                        message: `In order for us to start processing your quote and for you to view your quotes on Equipio, we need to verify your account. Please submit all required documentation on the profile page and click "Request Verification". Thank you.`,
                    });
                    this.router.navigateByUrl('/u/account/profile');
                }
                return contact;
            } catch (error) {
                console.error('Error fetching contact:', error);
                this.contactSubject.next(null);
                return null;
            }
        }
        return this.contactSubject.value;
    }

    toggleSort(sortState: { column: string | null; direction: 'asc' | 'desc' | 'none' }, column: string): {
        column: string;
        direction: 'asc' | 'desc' | 'none'
    } {
        if (sortState.column === column) {
            sortState.direction = sortState.direction === 'asc' ? 'desc' : 'asc';
        } else {
            sortState.column = column;
            sortState.direction = 'asc';
        }
        return sortState;
    }

    sortArray<T>(array: T[], sortState: {
        column: string | null;
        direction: 'asc' | 'desc' | 'none'
    }, compareFunction: (a: T, b: T) => number): T[] {
        if (!sortState.column || sortState.direction === 'none') return array;
        const isAsc = sortState.direction === 'asc';
        return array.sort((a, b) => isAsc ? compareFunction(a, b) : -compareFunction(a, b));
    }

    compare(a: any, b: any): number {
        if (a instanceof Date && b instanceof Date) {
            return a.getTime() - b.getTime();
        } else if (typeof a === 'string' && typeof b === 'string') {
            return a.localeCompare(b);
        } else if (typeof a === 'number' && typeof b === 'number') {
            return a - b;
        } else {
            return 0;
        }
    }

    saveFormState(form: FormGroup): void {
        let formData = form.getRawValue();
        const {start_date, end_date, documentFiles, ...formDataToSave} = formData;
        localStorage.setItem(this.formStateKey, JSON.stringify(formDataToSave));
    }

    loadFormState(): any {
        const data = localStorage.getItem(this.formStateKey);
        return data ? JSON.parse(data) : null;
    }

    clearFormState(): void {
        localStorage.removeItem(this.formStateKey);
    }

}
