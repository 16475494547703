import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@Component({
    selector: 'eqip-input-counter',
    imports: [CommonModule, ReactiveFormsModule, FormsModule],
    standalone: true,
    template: `
        <div class="flex items-center justify-center mt-2">
            <div class="p-1">
                <button type="button" class="rounded-full w-8 h-8 bg-white hover:bg-accent hover:text-white focus:outline-none flex items-center justify-center" (click)="decrement()" aria-label="Decrement">
                    <span class="text-base font-semibold">-</span>
                </button>
            </div>
            <input type="text" placeholder="1" class="text-center mx-2 py-1 border-b-2 border-gray-200 rounded focus:border-accent focus:outline-none" [(ngModel)]="quantity" style="width: 3rem;" (click)="$event.stopPropagation()" (input)="validateQuantity($event)">
            <div class="p-1">
                <button type="button"  class="rounded-full w-8 h-8 bg-transparent bg-white hover:bg-accent hover:text-white focus:outline-none flex items-center justify-center" (click)="increment()" aria-label="Increment">
                    <span class="text-base font-semibold">+</span>
                </button>
            </div>
        </div>
    `,
})
export class InputCounterComponent {
    @Input() quantity: number = 1;
    @Output() quantityChange = new EventEmitter<number>();

    increment() {
        this.quantity = isNaN(this.quantity) ? 1 : this.quantity;
        this.quantity++;
        this.quantityChange.emit(this.quantity);
    }

    decrement() {
        this.quantity = isNaN(this.quantity) ? 1 : this.quantity;
        if (this.quantity > 1) {
            this.quantity--;
        }
        this.quantityChange.emit(this.quantity);
    }

    validateQuantity(event: Event) {
        const input = event.target as HTMLInputElement;
        const inputValue = input.value === '' ? '1' : input.value;
        const validatedValue = parseInt(inputValue, 10);

        if (!isNaN(validatedValue) && validatedValue > 0) {
            this.quantity = validatedValue;
        } else {
            this.quantity = 1;
        }
        this.quantityChange.emit(this.quantity);
        input.value = this.quantity.toString();
    }
}
