import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Page} from '@nxt/model-core';
import {AsyncPipe, CommonModule, NgFor, NgIf} from '@angular/common';
import {RouterModule} from '@angular/router';
import {footerConfig} from "../../config/configs";


@Component({
    selector: 'eqip-footer',
    standalone: true,
    imports: [
        CommonModule, RouterModule, AsyncPipe
    ],
    template: `

        <footer class="bg-white pt-10 pb-10 border-t border-gray-300">
            <div class="container mx-auto flex flex-col items-center lg:flex-row lg:justify-between lg:items-start">
                <div class="text-center lg:text-left mb-8 lg:mb-0 flex flex-col items-center lg:items-start">
                    <img [src]="section?.image" alt="Logo" title="logo" class="w-36 h-10 mb-4"/>
                    <div>
                        <p class="text-gray-500 mt-2 lg:mt-0">{{section?.address}}</p>
                        <p class="text-gray-500">{{section?.email}}</p>
                        <p class="text-gray-500">{{section?.phone}}</p>
                    </div>
                </div>

                <div class="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4 text-center lg:text-left text-gray-500 w-full lg:w-auto">
                    <div *ngFor="let column of section?.columns">
                        <h3 class="text-sm font-bold text-gray-700">{{column?.header}}</h3>
                        <ul class="mt-4 space-y-2">
                            <ng-container *ngFor="let row of column?.rows">
                                <li><a href="{{row?.url}}" class="hover:text-accent-600">{{row?.page_label}}</a></li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>

    `
})
export class EqipFooter implements OnChanges {
    @Input() section: any;
    @Input() page:Page;

    ngOnChanges(changes: SimpleChanges): void {
        if (this.section || this.page?.footer?.content?.sections?.length) {
            this.section = this.section || this.page?.footer.content.sections[0];
        }
        // else {
        //     this.section = footerConfig;
        // }
    }
}
