import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from "@angular/common";

@Component({
    selector: 'app-slide-submenu',
    standalone:true,
    imports: [
        CommonModule, RouterModule,
    ],
    template: `
        <div [ngClass]="{'submenu-container': true, 'open': isOpen, 'closing': isClosing}" [style.background-color]="bgColor">
            <button (click)="closeSubMenu()" class="flex items-center my-2">
                <svg class="w-4 h-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
                </svg>
                <span>Back</span>
            </button>
            <ul class="grid grid-cols-2 gap-x-4 gap-y-2">
                <li *ngFor="let item of items; let i = index" [style.background-color]="bgColor">
                    <a [routerLink]="item.link" routerLinkActive="border-b-2 border-accent"
                       [style.background-color]="bgColor" [style.color]="textColor" class="block px-4 py-2 h-full"><span
                        class="border-b-2 border-transparent hover:border-b-2 hover:border-black transition duration-800 ease-in-out">{{ item.label }}</span></a>
                </li>
            </ul>
        </div>

    `,
})
export class SlideSubmenuComponent {
    @Input() isOpen: boolean = false;
    @Input() bgColor: string = 'white';
    @Input() textColor: string = 'black'
    isClosing: boolean = false;
    @Input() items: Array<{ label: string, link: string }> = [];
    @Output() closeSubMenuRequest = new EventEmitter<void>();

    constructor() { }

    ngOnChanges(changes: SimpleChanges) {
    }

    closeSubMenu(): void {
        // Set isClosing to true to trigger the slide out animation
        this.isClosing = true;
        // Wait for the animation to complete before emitting the close event
        setTimeout(() => {
            this.closeSubMenuRequest.emit(); // Notify the parent component
            this.isClosing = false; // Reset closing state
        }, 500); // Match the duration of the animation
    }
}
