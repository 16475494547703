import {Component, EventEmitter, Output} from '@angular/core';
import {CommonModule} from "@angular/common";

@Component({
    selector: 'items-per-page',
    standalone:true,
    imports: [CommonModule],
    template: `
        <div class="relative inline-block text-left">
            <button id="dropdownDefaultButton" data-dropdown-toggle="dropdown"
                    class="bg-black text-sm md:text-lg text-white hover:bg-gray-700 transition duration-300 ease-in-out px-4 py-2 rounded flex items-center whitespace-nowrap"
                    type="button" (click)="toggleDropdown()">
                Items per page: {{ selectedItemsPerPage }}
                <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                </svg>
            </button>

            <div id="dropdown" [class.hidden]="!isDropdownOpen"
                 class="z-50 absolute bg-gray-700 divide-y divide-gray-100 rounded-lg shadow w-44 mt-2">
                <ul class="py-2 text-sm text-gray-200" aria-labelledby="dropdownDefaultButton">
                    <li *ngFor="let itemsPerPage of itemsPerPageOptions">
                        <a href="#" class="block px-4 py-2 hover:bg-gray-400"
                           (click)="selectItemsPerPage(itemsPerPage); $event.preventDefault()">{{ itemsPerPage }}</a>
                    </li>
                </ul>
            </div>
        </div>
    `
})

export class ItemsPerPageComponent {
    @Output() itemsPerPageChange = new EventEmitter<number>();
    itemsPerPageOptions: number[] = [12, 24, 36];
    selectedItemsPerPage: number = this.itemsPerPageOptions[0];
    isDropdownOpen: boolean = false;

    selectItemsPerPage(itemsPerPage: number): void {
        this.selectedItemsPerPage = itemsPerPage;
        this.itemsPerPageChange.emit(itemsPerPage);
        this.isDropdownOpen = false;
    }

    toggleDropdown(): void {
        this.isDropdownOpen = !this.isDropdownOpen;
    }
}
