import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'app-hierarchical-menu',
    standalone:true,
    imports: [CommonModule],
    template: `
        <div *ngFor="let category of subcategories" class="my-2 px-5">
            <!-- Level 1 -->
            <div (click)="onSubcategorySelected(category.label)" class="flex justify-between items-center bg-white p-3 rounded-lg shadow-md cursor-pointer hover:bg-gray-200">
                <div class="font-semibold text-gray-800 flex-grow">
                    {{ category.label }}
                </div>
                <button *ngIf="category.subcategories && category.subcategories.length > 0" (click)="toggleExpand(category, $event)" class="text-gray-600 hover:text-gray-800">
                    <svg [ngClass]="{'rotate-180': category.expanded}" class="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
                    </svg>
                </button>
            </div>
            <div *ngIf="category.expanded" class="ml-4">
                <div *ngFor="let subcategory of category.subcategories" class="my-1">
                    <!-- Level 2 -->
                    <div class="flex justify-between items-center bg-gray-100 p-2 rounded-lg shadow-sm hover:bg-gray-200">
                        <div (click)="onSubcategorySelected(subcategory.label)" class="cursor-pointer flex-grow">
                            {{ subcategory.label }}
                        </div>
                        <button *ngIf="subcategory.subcategories && subcategory.subcategories.length > 0" (click)="toggleExpand(subcategory, $event)" class="text-gray-500 hover:text-gray-700">
                            <svg [ngClass]="{'rotate-180': subcategory.expanded}" class="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
                            </svg>
                        </button>
                    </div>
                    <div *ngIf="subcategory.expanded" class="ml-4">
                        <div *ngFor="let subsubcategory of subcategory.subcategories" class="my-1">
                            <!-- Level 3 -->
                            <div class="flex justify-between items-center bg-white p-2 rounded-lg shadow hover:bg-gray-100">
                                <div (click)="onSubcategorySelected(subsubcategory.label)" class="cursor-pointer">
                                    {{ subsubcategory.label }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  `,
    styles: []
})
export class HierarchicalMenuComponent {
    @Input() subcategories: any[] = [];
    @Output() subcategorySelected = new EventEmitter<string>();

    constructor() {
        this.initializeExpandedStates(this.subcategories);
    }

    initializeExpandedStates(categories: any[]) {
        categories.forEach(category => {
            category.expanded = false;
            if (category.subcategories) {
                this.initializeExpandedStates(category.subcategories);
            }
        });
    }

    toggleExpand(category: any, event: MouseEvent) {
        event.stopPropagation();
        category.expanded = !category.expanded;
    }

    onSubcategorySelected(subcategory: string) {
        this.subcategorySelected.emit(subcategory);
    }
}
