import {ChangeDetectorRef, Component, HostListener} from '@angular/core';

import {CommonModule} from '@angular/common';
import {ActivatedRoute, RouterModule} from '@angular/router';
import {AlgoliaSearchComponent} from '@library/shared/search/algolia-search.component';
import {AlgoliaService} from '../services/algolia.service';
import {PopButtonComponent} from "@library/shared/buttons/pop-button.component";
import {FacetListComponent} from "../_components/algolia_components/app-facet-list.component";
import {SearchResultsComponent} from "../_components/algolia_components/search-results.component";
import {SearchBarComponent} from "../_components/algolia_components/search-bar.component";
import {PaginationComponent} from "../_components/algolia_components/pagination.component";
import {HierarchicalMenuComponent} from "../_components/algolia_components/hierarchical-menu.component";
import {LayoutToggleComponent} from "../_components/algolia_components/app-layout-toggle.component";
import {ItemsPerPageComponent} from "../_components/algolia_components/items-per-page.component";
import {EqipNavHeader} from "../_components/header/eqip-nav-header";
import {PageService} from "@library/shared/_services/page.service";
import {IconsComponent} from "@library/shared/icons/icons.component";
import {SpinnerComponent} from "@library/shared/spinner/spinner.component";
import {EqipFooter} from "../_components/footer/eqip-footer";
import {FlexComponent} from "@library/shared/flex/flex.component";
import {DualSliderComponent} from "../_components/algolia_components/dual-slider.component";

//added last minute for count functionality
interface FacetWithCount {
    value: string;
    count: number;
}

interface FacetConfig {
    name: string;
    data: FacetWithCount[];
    label: string;
}

@Component({
    selector: 'equipment-page',
    standalone: true,
    imports: [
        CommonModule, RouterModule, AlgoliaSearchComponent, PopButtonComponent, FacetListComponent, SearchResultsComponent, SearchBarComponent, PaginationComponent, HierarchicalMenuComponent, LayoutToggleComponent, ItemsPerPageComponent, EqipNavHeader, IconsComponent, SpinnerComponent, EqipFooter, FlexComponent, DualSliderComponent
    ],

    template: `
        <eqip-nav-header></eqip-nav-header>
        <div class="relative mt-19 bg-cover bg-no-repeat h-[600px] bg-fixed"
             [style.background-image]="'url(' + imageUrl + ')'"
             style="background-position: 20% 35%;">

            <div class="absolute inset-0 bg-gradient-to-b from-black/60 via-black/30 to-black/75"></div>

            <div class="absolute top-28 inset-x-0 text-center px-6 py-4 z-40">
                <h2 class="text-lg md:text-7xl font-semibold text-white">{{ message.heading }}</h2>
                <!--                <p class="mt-6 text-xs md:text-sm text-gray-300">-->
                <!--                    {{ message.content.split(' ').slice(0, 25).join(' ') }}<br>-->
                <!--                    {{ message.content.split(' ').slice(25).join(' ') }}-->
                <!--                </p>            -->
            </div>

            <app-search-bar [category]="category" (search)="onSearch($event)"></app-search-bar>

        </div>
        <div class="active-filters sm:h-24 py-4 px-10 overflow-y-auto max-h-36 space-y-2">
            <div class="flex flex-wrap gap-2">
                <ng-container *ngFor="let attr of getActiveFacetKeys()">
                    <ng-container *ngFor="let value of activeFacets[attr]">
                <span class="filter-pill bg-gray-200 text-gray-800 px-3 py-1 rounded-full text-sm flex items-center">
                    {{ formatAttributeName(attr) }}: {{ value }}
                    <button class="remove-button ml-2 text-red-500 font-bold"
                            (click)="removeFilter(attr, value)">X</button>
                </span>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="w-full min-h-screen grid grid-cols-12 2xl:pr-40 2xl:pl-52">
            <div
                class="hidden md:block border-r-2 border-gray-300 col-span-3 p-10 h-full max-h-[150vh] scrollbar overflow-y-auto scrollbar-thumb-gray-900">
                <h2 class="text-xl text-white bg-black px-6 flex rounded-lg justify-between items-center p-2">
                    Subcategory</h2>
                <!--                this is hierachial menu which is a menu with submenu's-->
                <app-hierarchical-menu class="mb-2" [subcategories]="subcategories"
                                       (subcategorySelected)="onSubcategorySelected($event)">
                </app-hierarchical-menu>
                <div *ngFor="let facet of facets">
                    <ng-container *ngIf="facet.label !== 'Subcategory'">
                        <app-facet-list [facets]="facet.data"
                                        [activeFacets]="activeFacets"
                                        [facetAttribute]="facet.name"
                                        (facetSelected)="onFacetSelected($event, facet.name)"
                                        heading="{{ facet.label }}">
                        </app-facet-list>
                    </ng-container>
                </div>
            </div>
            <div class="col-span-9 p-2">
                <div class="section-header text-center mb-4">
                    <h1 class="text-2xl font-semibold text-gray-800">
                        {{ selectedSubcategoryLabel ? 'Category: ' + selectedSubcategoryLabel : 'All Products' }}
                    </h1>
                </div>
                <div class="w-6/8 mb-2 flex flex-col sm:flex-row justify-around items-center gap-2 sm:gap-4">
                    <div class="text-center mb-0 order-1 sm:order-2">
                        <div class="total-results-count text-sm md:text-lg text-white bg-black px-6 py-2 rounded">
                            Total {{ category }} Products Found: <span
                            class="text-accent-300 font-semibold">{{ totalProductsFound }}</span>
                        </div>
                    </div>
                    <div class="flex w-full sm:w-auto justify-center sm:justify-start gap-4 order-2 sm:order-1">
                        <app-layout-toggle class="hidden sm:block"
                                           (layoutChanged)="onLayoutChange($event)"></app-layout-toggle>
                    </div>
                    <div class="flex w-full sm:w-auto justify-center sm:justify-start gap-4 order-3 sm:order-3">
                        <button
                            class="filter-button text-sm md:text-md border-2 border-black bg-black text-white transition duration-300 ease-in-out px-4 rounded flex items-center"
                            (click)="toggleFilters()">
                            <icon name="heroicon-outline-adjustments-horizontal" class="w-4 mr-2"></icon>
                            {{ showFilters ? 'Hide Filters' : 'Show Filters' }}
                        </button>
                        <items-per-page (itemsPerPageChange)="onItemsPerPageChange($event)"></items-per-page>
                    </div>
                </div>
                <div *ngIf="showFilters" class="mobile-filters">
                    <h2 class="text-xl text-white bg-black px-6 flex justify-between items-center p-2">Subcategory</h2>
                    <app-hierarchical-menu class="mb-2" [subcategories]="subcategories"
                                           (subcategorySelected)="onSubcategorySelected($event)">
                    </app-hierarchical-menu>
                    <div *ngFor="let facet of facets">
                        <ng-container *ngIf="facet.label !== 'Subcategory'">
                            <app-facet-list [facets]="facet.data"
                                            [activeFacets]="activeFacets"
                                            [facetAttribute]="facet.name"
                                            (facetSelected)="onFacetSelected($event, facet.name)"
                                            heading="{{ facet.label }}">
                            </app-facet-list>

                        </ng-container>
                    </div>
                </div>
<!--                <ng-container *ngIf="!isSliderLoading">-->
<!--                    <div *ngFor="let slider of sliders">-->
<!--                        <dual-slider-->
<!--                            *ngIf="sliderMinMax[slider.min]?.min !== null && sliderMinMax[slider.max]?.max !== null"-->
<!--                            [label]="slider.label"-->
<!--                            [min]="sliderMinMax[slider.min]?.min || 0"-->
<!--                            [max]="sliderMinMax[slider.max]?.max || 100"-->
<!--                            (rangeChanged)="onSliderRangeChange(slider.min, slider.max, $event)">-->
<!--                        </dual-slider>-->
<!--                    </div>-->
<!--                </ng-container>-->
                <div class="flex w-full justify-center">
                    <h1 class="border border-gray-200 rounded-full m-1 w-11/12 bg-gray-200"></h1>
                </div>
                <div *ngIf="!isLoading">
                    <app-search-results [searchResults]="searchResults" [layout]="layout"></app-search-results>
                </div>
                <div *ngIf="isLoading" class="flex w-full items-center justify-center mt-28">
                    <spinner class="mx-auto h-30 w-30 text-accent-700"></spinner>
                </div>
                <div *ngIf="!isLoading" class="flex w-full justify-center">
                    <h1 class="border border-gray-200 rounded-full m-1 w-11/12 bg-gray-200"></h1>
                </div>
                <app-pagination *ngIf="!isLoading"
                                [currentPage]="currentPage"
                                [totalPages]="totalPages"
                                (pageChanged)="goToPage($event)">
                </app-pagination>
            </div>
        </div>
        <eqip-footer [page]="pSvc.page$|async"></eqip-footer>
    `,
    styles: [`
        @media (max-width: 1200px) {
            .scrollbar {
                display: none;
            }
            .grid .col-span-9 {
                grid-column: span 12;
            }
        }

        @media (min-width: 1200px) {
            .filter-button {
                display: none;
            }
        }
    `]
})
export class EquipmentPage {
    // sliders = [];
    // sliderMinMax: { [key: string]: { min: number, max: number } } = {};
    facets = [];
    category: string = '';
    //this is for my subcategory hierachiel menu.
    subcategories: any[] = [];
    subcategoryParentMap: Map<string, string>;
    //this is the array for search results that get passed to the search result component
    //background-color: #F6F5F2FF;
    searchResults: any[] = [];
    //this is an object to keep track of what active facets are on.
    activeFacets: { [key: string]: string[] } = {};
    //for changing the results layout
    layout: string = 'grid';
    //this is for pagination
    hitsPerPage: number = 12;
    currentPage: number = 0;
    totalPages: number = 0;
    //this if or my items per page logic
    itemsPerPageOptions: number[] = [12, 24, 36];
    //for filter dropdown on small screen sizes
    showFilters = false;
    //Bring in the algolia service for us to use.
    // store the last property so when i click on the next page it doesn't reset
    private lastSearchQuery: string = '';
    //so my segement search on doesn't get overwritten(activated route)=>deprecated i think
    private segmentFilters: string = '';
    //for top background image
    imageUrl: string = '';
    //for the number of products at the top
    totalProductsFound: number = 0;
    //for the message under the background image at the top
    message: { heading: string, content: string } = {heading: '', content: ''};
    selectedSubcategoryLabel: string = '';
    isLoading: boolean = false;
    // isSliderLoading: boolean = true;
    constructor(
        private algoliaService: AlgoliaService,
        private route: ActivatedRoute,
        public pSvc: PageService,
        private cdr: ChangeDetectorRef
        ) {

    }

    //on page load. It will run when the page is first created
    ngOnInit() {
        this.getRouteSegments();
        this.route.data.subscribe(data => {
            const config = data.config;
            this.facets = config.facets;
            // this.sliders = config.sliders;
            this.imageUrl = config?.imageUrl;
            this.message = config.message;
        });
        this.performSearchWithActiveFacets(this.currentPage);
        // await this.fetchSliderMinMaxValues();
        // this.isSliderLoading = false;
        this.updateAllFacets();
        this.updateSubcategories();
        this.cdr.detectChanges();

    }

    //Grab the values for my facet list filters initially. Then update as more filters get selected.
    //So this is also to narrow down my filters when I click on one. For instance if I click on brand:canon it should narrow down Resolution to only the resolution that canon supports. Instead of all the resolutions.
    private updateAllFacets() {
        //grabs all active filters.
        //ex. "(properties.brand:"Arri" OR properties.brand:"Canon") AND (properties.resolution:"4K")"
        const currentFilters = this.buildCurrentFilters();
        //prolly gotta delete this line
        // this.algoliaService.getFacetValues('properties.brand', currentFilters, 100).then(facets => {
        //     this.brands = facets; // facets now include both value and count
        // });

        this.facets.forEach(facet => {
            this.fetchFacetValues(facet.name, currentFilters, (values) => facet.data = values, this.category);
        });

    }

    private updateDependentFacets() {
        //grabs all active filters.
        //ex. "(properties.brand:"Arri" OR properties.brand:"Canon") AND (properties.resolution:"4K")"
        const currentFilters = this.buildCurrentFilters();
        // Update facets like resolution based on selected brands
        this.facets.forEach(facet => {
            this.fetchFacetValues(facet.name, currentFilters, (values) => facet.data = values, this.category);
        });

        // Perform the search after updating facets
        this.performSearchWithActiveFacets();
    }

    //The function grabs the facet values and lists them. It calls my algolia service function getFacetValues.
    private fetchFacetValues(facetName: string, filters: string, setFacetValues: (values: FacetWithCount[]) => void, category: string) {
        this.algoliaService.getFacetValues(facetName, filters, 100, category).then(facetsWithCount => {
            // 'values' is already an array of strings, so use it directly
            setFacetValues(facetsWithCount);
        }).catch(error => {
            console.error('Error fetching facet values for', facetName, ':', error);
        });
    }

    /*
    BuildCurrentFilters grabs all of the current filters and makes them into strings. Tweak to this is for inches and feet in strings which trips up Algolia. Escaping doesn't help
    This became a time sink
     */
    private buildCurrentFilters(): string {
        return Object.keys(this.activeFacets)
            .map(attr => {
                const facetValues = this.activeFacets[attr].filter(value => value && value.trim().length > 0);

                if (facetValues.length > 0) {
                    return facetValues.map(value => {

                        //for dual-slider if i bring it back.
                        // if (attr.includes('_min')) {
                        //     const maxAttr = attr.replace('_min', '_max');
                        //     if (this.activeFacets[maxAttr]?.[0]) {
                        //         return `${attr} >= ${value} AND ${maxAttr} <= ${this.activeFacets[maxAttr][0]}`;
                        //     }
                        //     return `${attr} >= ${value}`;
                        // } else if (attr.includes('_max')) {
                        //     const minAttr = attr.replace('_max', '_min');
                        //     if (this.activeFacets[minAttr]?.[0]) {
                        //         return '';
                        //     }
                        //     return `${attr} <= ${value}`;
                        // }

                        if (value.includes('"')) {
                            return `${attr}:'${value}'`;
                        } else {
                            return `${attr}:"${value}"`;
                        }
                    }).filter(Boolean).join(' AND ');
                }
                return '';
            })
            .filter(filter => filter.trim().length > 0)
            .join(' AND ');
    }

    /*
    Search Bar Component Function. It is passed to our search bar and accepts the search inputs string as the query that gets passed to Algolia.
     */
    onSearch(query: any): void {
        // Ensure the query is a string, and trim it. Was getting errors when it was undefined.
        const searchQuery = query && query.target ? query.target.value.trim() : (typeof query === 'string' ? query : '').trim();
        this.activeFacets = {};
        this.lastSearchQuery = query;
        this.currentPage = 0;

        this.isLoading = true;

        if (!searchQuery) {
            this.fetchInitialResults();
        } else {
            this.performSearch(query);
        }
    }

    private fetchInitialResults() {
        // Use an empty string for broad search
        const defaultQuery = '';
        this.algoliaService.search(defaultQuery, {page: 0, hitsPerPage: this.hitsPerPage, category: this.category})
            .then(response => {
                this.searchResults = response.hits.filter((hit: any) => hit._type !== 'pages');
                this.totalPages = response.nbPages;
                this.totalProductsFound = response.nbHits;
                this.currentPage = 0;
                this.isLoading=false;
            })
            .catch(error => {
                this.isLoading=false;
                console.error('Error fetching initial results:', error);
            });
    }

    //creates the active facet object
    onFacetSelected(facetData: { facet: string, isSelected: boolean }, facetAttribute: string) {
        if (!this.activeFacets[facetAttribute]) {
            this.activeFacets[facetAttribute] = [];
        }

        if (facetData.isSelected) {
            // Add the selected facet to the activeFacets array if it's not already present
            if (!this.activeFacets[facetAttribute].includes(facetData.facet)) {
                this.activeFacets[facetAttribute].push(facetData.facet);
            }
        } else {
            // Remove the unselected facet from the activeFacets array
            this.activeFacets[facetAttribute] = this.activeFacets[facetAttribute].filter(f => f !== facetData.facet);
            if (this.activeFacets[facetAttribute].length === 0) {
                delete this.activeFacets[facetAttribute];
            }
        }

        // Update the facets and search results
        this.updateDependentFacets();
    }

    getActiveFacetKeys(): string[] {
        return Object.keys(this.activeFacets);
    }

    private performSearchWithActiveFacets(page: number = 0) {
        // Added so that if there is searchQuery it blanks out.
        if (Object.keys(this.activeFacets).length === 0) {
            this.lastSearchQuery = '';
        }
        //grabs all active filters.
        //ex. "(properties.brand:"Arri" OR properties.brand:"Canon") AND (properties.resolution:"4K")"
        const filters = this.buildCurrentFilters();
        console.log(filters)
        //grabs the current page number for pagination.
        this.currentPage = page;
        //do an algolia search with the filters, page number, and the amount of hits per page we want.
        //then update the results and total pages depending on how many hits we get
        this.algoliaService.search(this.lastSearchQuery, {
            filters,
            page,
            hitsPerPage: this.hitsPerPage,
            category: this.category
        }).then(response => {
            this.searchResults = response.hits.filter((hit: any) => hit._type !== 'pages');
            this.totalProductsFound = response.nbHits;
            this.totalPages = response.nbPages;
        }).catch(error => {
            console.error('Error fetching results:', error);
        });
    }

    /*
    Grab all the facet values for the subcategory levels and then use buildSubcategoryHiearcy to assign it to my this.subcategories variable.
     */
    private updateSubcategories() {
        Promise.all([
            this.algoliaService.getFacetValues('properties.subcategory_lvl1', '', 100, this.category),
            this.algoliaService.getFacetValues('properties.subcategory_lvl2', '', 100, this.category),
            this.algoliaService.getFacetValues('properties.subcategory_lvl3', '', 100, this.category),
            this.algoliaService.getFacetValues('properties.subcategory_lvl4', '', 100, this.category)
        ]).then(([lvl1Values, lvl2Values, lvl3Values, lvl4Values]) => {
            const lvl1Facets = lvl1Values.map(value => ({value}));
            const lvl2Facets = lvl2Values.map(value => ({value}));
            const lvl3Facets = lvl3Values.map(value => ({value}));
            const lvl4Facets = lvl4Values.map(value => ({value}));
            this.subcategories = this.buildSubcategoryHierarchy(lvl1Facets, lvl2Facets, lvl3Facets, lvl4Facets);

            // Build the subcategory parent map after updating subcategories
            this.buildSubcategoryParentMap();
        }).catch(error => {
            console.error('Error fetching subcategory facets:', error);
        });
    }

    //this is to build the subcategory map. Originally it was a pyramid array of object like structure. I made this more efficient utilizing map.
    private buildSubcategoryParentMap() {
        this.subcategoryParentMap = new Map<string, string>();
        for (let lvl1 of this.subcategories) {
            for (let lvl2 of lvl1.subcategories || []) {
                this.subcategoryParentMap.set(lvl2.label, lvl1.label);
                for (let lvl3 of lvl2.subcategories || []) {
                    this.subcategoryParentMap.set(lvl3.label, lvl2.label);
                    for (let lvl4 of lvl3.subcategories || []) {
                        this.subcategoryParentMap.set(lvl4.label, lvl3.label);
                    }
                }
            }
        }
    }

    /*
    This is to build my array of objects hierarchial menu structure. So for example. First level label Camera Accessories will have an array of objects called subcategories.
    These objects will be level 3 subcategories. For this example labels for each object would be follow focus, miscellaneous, mattebox, camera power...
    which will also have a subcategory property which will be populated if there is level 3 subcategories
     */
    // I put the ' > ' logic in so a level 3 subcategory string would look like Camera Accessories > Camera Power > Batteries. Which Helps for the menu.
    // This helped me find the parent label in the array. So It knows which object to go into.
    private buildSubcategoryHierarchy(lvl1Facets, lvl2Facets, lvl3Facets, lvl4Facets): any[] {
        // Transform flat facet lists into a hierarchical structure. This changed cause i added count
        let hierarchy = lvl1Facets.map(facetData => ({
            label: facetData.value.value,
            subcategories: []
        }));
        //replacement function for foreach repetition
        const processFacet = (facetData, parentHierarchy, depth) => {
            const facetValue = facetData.value.value;

            const parts = facetValue.split(' > ');
            if (parts.length !== depth) {
                console.error(`Invalid facet value format for ${facetValue}`);
                return;
            }

            // Find or create parent category
            let parent = parentHierarchy.find(cat => cat.label === parts[0]);
            if (!parent) {
                parent = {label: parts[0], subcategories: []};
                parentHierarchy.push(parent);
            }

            // Navigate through subcategories based on depth
            let current = parent;
            for (let i = 1; i < parts.length; i++) {
                let child = current.subcategories.find(sub => sub.label === parts[i]);
                if (!child) {
                    child = {label: parts[i], subcategories: []};
                    current.subcategories.push(child);
                }
                current = child;
            }
        };

        lvl2Facets.forEach(facet => processFacet(facet, hierarchy, 2));
        lvl3Facets.forEach(facet => processFacet(facet, hierarchy, 3));
        lvl4Facets.forEach(facet => processFacet(facet, hierarchy, 4));
        return hierarchy;
    }

    // This is to get the results for a filter selected. It takes in the subcategory you selected
    onSubcategorySelected(subcategory: string) {
        this.selectedSubcategoryLabel = subcategory;
        // Reset all active filters
        this.activeFacets = {};
        this.lastSearchQuery = '';

        // Determine the facet attribute based on the subcategory level
        const subcategoryLevel = this.findSubcategoryLevel(subcategory);
        let facetAttribute = '';
        let facetValue = subcategory;

        if (subcategoryLevel === 1) {
            facetAttribute = 'properties.subcategory_lvl1';
        } else if (subcategoryLevel === 2) {
            facetAttribute = 'properties.subcategory_lvl2';
            const parentLabel = this.findParentLabel(subcategory);
            facetValue = parentLabel + " > " + subcategory;
        } else if (subcategoryLevel === 3) {
            facetAttribute = 'properties.subcategory_lvl3';
            const childLabel = subcategory;
            const parentLabel = this.findParentLabel(childLabel);
            const grandparentLabel = this.findParentLabel(parentLabel);
            facetValue = grandparentLabel + " > " + parentLabel + " > " + childLabel;
        } else if (subcategoryLevel === 4) {
            facetAttribute = 'properties.subcategory_lvl4';
            const lvl4Label = subcategory;
            const lvl3Label = this.findParentLabel(lvl4Label);
            const lvl2Label = this.findParentLabel(lvl3Label);
            const lvl1Label = this.findParentLabel(lvl2Label);
            facetValue = lvl1Label + " > " + lvl2Label + " > " + lvl3Label + " > " + lvl4Label;
        }

        // Set the new filter based on the selected subcategory
        this.activeFacets[facetAttribute] = [facetValue];
        this.performSearchWithActiveFacets();
        this.updateAllFacets();
    }

    private findSubcategoryLevel(subcategory: string): number {
        for (let lvl1 of this.subcategories) {
            if (lvl1.label === subcategory) return 1;
            for (let lvl2 of lvl1.subcategories || []) {
                if (lvl2.label === subcategory) return 2;
                for (let lvl3 of lvl2.subcategories || []) {
                    if (lvl3.label === subcategory) return 3;
                    for (let lvl4 of lvl3.subcategories || []) {
                        if (lvl4.label === subcategory) return 4;
                    }
                }
            }
        }
        return 0;
    }

    //this is also optimized because of map.
    private findParentLabel(subcategory: string): string {
        return this.subcategoryParentMap.get(subcategory) || '';
    }

    removeFilter(facetAttribute: string, facetValue: string): void {
        this.activeFacets[facetAttribute] = this.activeFacets[facetAttribute].filter(f => f !== facetValue);
        if (this.activeFacets[facetAttribute].length === 0) {
            delete this.activeFacets[facetAttribute];
        }

        if (facetAttribute.startsWith('properties.subcategory_')) {
            this.selectedSubcategoryLabel = '';
        }
        this.updateDependentFacets();
    }

    formatAttributeName(attr) {
        const facet = this.facets.find(f => f.name === attr);
        return facet ? facet.label : attr;
    }

    goToPage(page: number) {
        this.currentPage = page;
        this.performSearchWithActiveFacets(page);
    }

    onLayoutChange(newLayout: string) {
        this.layout = newLayout;
    }

    onItemsPerPageChange(itemsPerPage: number) {
        this.hitsPerPage = itemsPerPage;
        this.currentPage = 0;
        this.performSearchWithActiveFacets(this.currentPage);
    }

    formatHeading(arrayName: string): string {
        return arrayName.split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    //for responsive screen.
    toggleFilters() {
        this.showFilters = !this.showFilters;
    }

    //This is so when you are on small screen and you hit show filters and then make the screen bigger the showfilters should turn false again.
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (window.innerWidth > 1200 && this.showFilters) {
            this.showFilters = false;
        }
    }

    private async getRouteSegments() {
        this.route.url.subscribe(urlSegments => {
            const pathSegments = urlSegments.map(segment => this.formatSegment(segment.path));
            if (pathSegments[1] === 'Bodies') {
                pathSegments[1] = 'Camera';
            }
            let filters = [];

            pathSegments.forEach((segment, index) => {
                if (index === 0) {
                    const categoryFilter = `properties.category:'${segment}'`;
                    this.category = segment;
                    // this.pSvc.titleService.setTitle(`${segment} Equipment`);
                    filters.push(categoryFilter);
                } else if (index === 1) {
                    const subcategoryFilter = `properties.subcategory_lvl1:'${segment}'`;
                    filters.push(subcategoryFilter);
                }
            });

            this.segmentFilters = filters.join(' AND ');
            this.performSearchWithFilter(this.segmentFilters);
        });
    }

    private formatSegment(segment: string): string {
        let decodedSegment = decodeURIComponent(segment);

        const partsWithDelimiters = decodedSegment.split(/([-\/])/);
        const processedParts = partsWithDelimiters.map((part, index, array) => {
            if (part === '-' || part === '/') {
                return part === '/' ? '/' : ' ';
            } else {
                return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
            }
        });

        return processedParts.join('');
    }

    private performSearchWithFilter(filter: string): void {
        const filters = filter;

        this.currentPage = 0;

        this.algoliaService.search(this.lastSearchQuery, {
            filters: filters,
            page: this.currentPage,
            hitsPerPage: this.hitsPerPage,
        }).then(response => {
            this.searchResults = response.hits.filter((hit: any) => hit._type !== 'pages');
            this.totalProductsFound = response.nbHits;
            this.totalPages = response.nbPages;
        }).catch(error => {
            console.error('Error performing search:', error);
        });
    }

    private performSearch(query: string): void {
        const filters = this.buildCurrentFilters();
        this.algoliaService.search(query, {
            filters,
            page: this.currentPage,
            hitsPerPage: this.hitsPerPage,
            category: this.category
        }).then(response => {
            this.searchResults = response.hits.filter((hit: any) => hit._type !== 'pages');
            this.totalProductsFound = response.nbHits;
            this.totalPages = response.nbPages;
            this.isLoading=false;
            this.updateFiltersBasedOnResults(response.hits);
        }).catch(error => {
            this.isLoading=false;
            console.error('Error searching:', error);
        });
    }

    private updateFiltersBasedOnResults(hits: any[]): void {
        const brands = new Set<string>();
        const otherFacets = {};

        hits.forEach(hit => {
            if (hit.properties?.brand) {
                brands.add(hit.properties.brand);
            }
        });
        this.activeFacets['properties.brand'] = Array.from(brands);
        this.updateAllFacets();
    }
//     async fetchSliderMinMaxValues() {
//         const fetchPromises = this.sliders.map(async (slider) => {
//             const minPromise = this.algoliaService.getFacetStats(slider.min,this.category).then(minMax => {
//                 if (!this.sliderMinMax[slider.min]) {
//                     this.sliderMinMax[slider.min] = { min: minMax.min, max: null };
//                 } else {
//                     this.sliderMinMax[slider.min].min = minMax.min;
//                 }
//             });
//
//             const maxPromise = this.algoliaService.getFacetStats(slider.max,this.category).then(minMax => {
//                 if (!this.sliderMinMax[slider.max]) {
//                     this.sliderMinMax[slider.max] = { min: null, max: minMax.max };
//                 } else {
//                     this.sliderMinMax[slider.max].max = minMax.max;
//                 }
//             });
//
//             await Promise.all([minPromise, maxPromise]);
//         });
//
//         await Promise.all(fetchPromises);
//
//         this.cdr.detectChanges();
//     }
//
//     onSliderRangeChange(minAttr: string, maxAttr: string, range: { min: number, max: number }) {
//         this.activeFacets[minAttr] = [range.min.toString()];
//         this.activeFacets[maxAttr] = [range.max.toString()];
//         console.log('i am this.activeFacets for sliders', this.activeFacets);
//         this.performSearchWithActiveFacets();
//     }
}


