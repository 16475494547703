import {Component, EventEmitter, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IconsComponent} from "@library/shared/icons/icons.component";

@Component({
    selector: 'app-layout-toggle',
    imports: [CommonModule, IconsComponent],
    standalone: true,
    template: `
        <div class="bg-gray-200 text-lg text-gray-500 leading-none rounded-full inline-flex">
            <button (click)="toggleLayout('grid')" [class]="gridButtonClasses" id="grid">
                <icon name="heroicon-solid-squares" class="w-5 mr-2"></icon>
                <span>Grid</span>
            </button>
            <button (click)="toggleLayout('list')" [class]="listButtonClasses" id="list">
                <icon name="heroicon-outline-list-bullet" class="w-5 mr-2"></icon>
                <span>List</span>
            </button>
        </div>
    `,
    styles: [`
        .translate-x-7 {
            transform: translateX(1.75rem); /* Tailwind class equivalent */
        }
    `]
})
export class LayoutToggleComponent {
    @Output() layoutChanged = new EventEmitter<string>();
    isGrid = true;
    //Love these getters
    get gridButtonClasses() {
        return `inline-flex items-center transition-colors duration-300 ease-in focus:outline-none cursor-pointer rounded-l-full px-4 py-2 ${this.isGrid ? 'bg-black text-white' : 'bg-gray-200 text-gray-500'}`;
    }

    get listButtonClasses() {
        return `inline-flex items-center transition-colors duration-300 ease-in focus:outline-none cursor-pointer rounded-r-full px-4 py-2 ${!this.isGrid ? 'bg-black text-white' : 'bg-gray-200 text-gray-500'}`;
    }

    toggleLayout(layout: string) {
        this.isGrid = (layout === 'grid');
        this.layoutChanged.emit(this.isGrid ? 'grid' : 'list');
    }
}
