// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    type: 'dev',
    appName: 'Equipio',
    nxtProjectId: 'nxtpart-dev',
    firebase: {
        apiKey: 'AIzaSyB82LW1Ksb70umo_3IHndNp5nJv6vihEEg',
        authDomain: 'eqip.nxtorder.co',
        projectId: 'eqip-dev',
        storageBucket: 'eqip-dev.appspot.com',
        messagingSenderId: '766588041793',
        appId: '1:766588041793:web:e64d8e5a62f8d0269ce4c0'
    },
    storageUrl: '',
    pageServiceSiteId:'',
    googleMaps:'AIzaSyC2IMUvCYXnZjV8OwjMC0baUkIpssOo2U8',
    push_public_key: '',
    default_client: {
        id: 'wje74hodg2b9wxy9j7izgp',
        name_key: 'EQIP',
        phone: '6077653315',
        email:'support@equipio.com',
        theme: {
            dark: '#1f5486',
            light: '#ffffff',
            accent: '#448ec0'
        },
        name: 'Equipio'
    },
    algolia: {
        appId: 'R4QRFG8HNF',
        searchKey: '329314321b79d35da39f0c7e45ddcaf3'
    },
    stripe: 'pk_test_51P6fyjJ6354NwzKd71cW6afoi4JIkoy15CdTXDrCm2nD7NOZxBNeMzyjUBLJaxKF8VeePxTUFoSThX9oCbQt7DUR00UV2Vwhpw',
    paypal: {client_id: ''}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
