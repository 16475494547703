import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {IconsComponent} from "@library/shared/icons/icons.component";

@Component({
    selector: 'app-search-bar',
    standalone: true,
    template: `
        <div class="absolute inset-0 bg-opacity-50 bg-black flex justify-center items-center">
            <div
                class="flex justify-center items-center w-5/6 sm:w-3/4 md:w-1/2 lg:w-1/3 xl:w-1/4 h-10 bg-white rounded-full shadow-lg">
                <input type="search"
                       class="flex-grow rounded-full border-none bg-transparent pl-3 pr-1 py-2 text-sm md:text-base text-gray-900 focus:outline-none focus:ring-2 focus:ring-transparent"
                       [placeholder]="'Search ' + (category ? category + ' ' : '') + 'products...'"
                       [(ngModel)]="searchQuery"
                       (keyup.enter)="onSearch()"/>
                <button
                    class="flex items-center justify-center w-8 h-8 md:w-10 md:h-10 bg-accent-500 text-white rounded-full ml-2 hover:bg-accent-600 disabled:bg-gray-400 shadow-md"
                    [disabled]="!searchQuery"
                    (click)="onSearch()">
                    <icon name="heroicon-outline-search" class="w-4 h-4 md:w-6 md:h-6"></icon>
                </button>
            </div>
        </div>
    `,
    imports: [
        FormsModule, CommonModule, IconsComponent
    ],
})
export class SearchBarComponent {
    //form string will save to searchQuery
    searchQuery: string = '';
    @Input() category: string = '';
    @Output() search = new EventEmitter<string>();

    /*
    On Click or Enter the function onSearch will trigger and emit the string up to the parent as an event. Which will then trigger the OnSearch function there to Algolia's search service
     */
    onSearch(): void {
        this.search.emit(this.searchQuery);
    }

    clearSearch(): void {
        this.searchQuery = '';
        this.search.emit(this.searchQuery);
    }
}
